/*!
 * icheck-material v1.0.0 (https://github.com/bantikyan/icheck-material)
 * Copyright 2018 Hovhannes Bantikyan.
 * Licensed under MIT (https://github.com/bantikyan/icheck-material/blob/master/LICENSE)
 */
[class*="icheck-material"] {
  min-height: 22px;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  padding-left: 0px; }
  [class*="icheck-material"] > label {
    padding-left: 29px !important;
    min-height: 22px;
    line-height: 22px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer; }
  [class*="icheck-material"] > input:first-child {
    position: absolute !important;
    opacity: 0;
    margin: 0;
    background-color: #787878;
    border-radius: 50%;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    display: block;
    pointer-events: none;
    width: 22px;
    height: 22px;
    outline: none;
    transform: scale(2);
    -ms-transform: scale(2);
    transition: opacity 0.3s, transform 0.3s; }
    [class*="icheck-material"] > input:first-child:disabled {
      cursor: default; }
      [class*="icheck-material"] > input:first-child:disabled + label,
      [class*="icheck-material"] > input:first-child:disabled + input[type="hidden"] + label,
      [class*="icheck-material"] > input:first-child:disabled + label::before,
      [class*="icheck-material"] > input:first-child:disabled + input[type="hidden"] + label::before {
        pointer-events: none;
        cursor: default;
        filter: alpha(opacity=65);
        -webkit-box-shadow: none;
        box-shadow: none;
        opacity: .65; }
    [class*="icheck-material"] > input:first-child + label::before,
    [class*="icheck-material"] > input:first-child + input[type="hidden"] + label::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 22px;
      height: 22px;
      border: 2px solid #787878;
      border-radius: 3px;
      margin-left: -29px;
      box-sizing: border-box; }
    [class*="icheck-material"] > input:first-child:checked + label::after,
    [class*="icheck-material"] > input:first-child:checked + input[type="hidden"] + label::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 7px;
      height: 10px;
      border: solid 2px #fff;
      border-left: none;
      border-top: none;
      transform: translate(7.75px, 4.5px) rotate(45deg);
      -ms-transform: translate(7.75px, 4.5px) rotate(45deg);
      box-sizing: border-box; }
    [class*="icheck-material"] > input:first-child:not(:checked):not(:disabled):hover + label::before,
    [class*="icheck-material"] > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
      border-width: 2px; }
    [class*="icheck-material"] > input:first-child::-ms-check {
      opacity: 0;
      border-radius: 50%; }
    [class*="icheck-material"] > input:first-child:active {
      transform: scale(0);
      -ms-transform: scale(0);
      opacity: 1;
      transition: opacity 0s, transform 0s; }
  [class*="icheck-material"] > input[type="radio"]:first-child + label::before,
  [class*="icheck-material"] > input[type="radio"]:first-child + input[type="hidden"] + label::before {
    border-radius: 50%; }
  [class*="icheck-material"] > input[type="radio"]:first-child:checked + label::before,
  [class*="icheck-material"] > input[type="radio"]:first-child:checked + input[type="hidden"] + label::before {
    background-color: transparent; }
  [class*="icheck-material"] > input[type="radio"]:first-child:checked + label::after,
  [class*="icheck-material"] > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: none;
    top: 6px;
    left: 6px;
    transform: none;
    -ms-transform: none; }
  [class*="icheck-material"] > input[type="checkbox"]:first-child:checked + label::after,
  [class*="icheck-material"] > input[type="checkbox"]:first-child:checked + input[type="hidden"] + label::after {
    width: 8px;
    height: 14px;
    transform: translate(7px, 2px) rotate(45deg);
    -ms-transform: translate(7px, 2px) rotate(45deg); }

.icheck-inline {
  display: inline-block; }
  .icheck-inline + .icheck-inline {
    margin-left: .75rem;
    margin-top: 6px; }

.icheck-material-red > input:first-child {
  background-color: #f44336; }
  .icheck-material-red > input:first-child::-ms-check {
    background-color: #f44336; }
  .icheck-material-red > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-red > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #f44336; }
  .icheck-material-red > input:first-child:checked + label::before,
  .icheck-material-red > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #f44336;
    border-color: #f44336; }
  .icheck-material-red > input:first-child:checked + label::after,
  .icheck-material-red > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-red > input[type="radio"]:first-child:checked + label::after,
.icheck-material-red > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #f44336; }

.icheck-material-pink > input:first-child {
  background-color: #e91e63; }
  .icheck-material-pink > input:first-child::-ms-check {
    background-color: #e91e63; }
  .icheck-material-pink > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-pink > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #e91e63; }
  .icheck-material-pink > input:first-child:checked + label::before,
  .icheck-material-pink > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #e91e63;
    border-color: #e91e63; }
  .icheck-material-pink > input:first-child:checked + label::after,
  .icheck-material-pink > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-pink > input[type="radio"]:first-child:checked + label::after,
.icheck-material-pink > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #e91e63; }

.icheck-material-purple > input:first-child {
  background-color: #9c27b0; }
  .icheck-material-purple > input:first-child::-ms-check {
    background-color: #9c27b0; }
  .icheck-material-purple > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-purple > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #9c27b0; }
  .icheck-material-purple > input:first-child:checked + label::before,
  .icheck-material-purple > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #9c27b0;
    border-color: #9c27b0; }
  .icheck-material-purple > input:first-child:checked + label::after,
  .icheck-material-purple > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-purple > input[type="radio"]:first-child:checked + label::after,
.icheck-material-purple > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #9c27b0; }

.icheck-material-deeppurple > input:first-child {
  background-color: #673ab7; }
  .icheck-material-deeppurple > input:first-child::-ms-check {
    background-color: #673ab7; }
  .icheck-material-deeppurple > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-deeppurple > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #673ab7; }
  .icheck-material-deeppurple > input:first-child:checked + label::before,
  .icheck-material-deeppurple > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #673ab7;
    border-color: #673ab7; }
  .icheck-material-deeppurple > input:first-child:checked + label::after,
  .icheck-material-deeppurple > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-deeppurple > input[type="radio"]:first-child:checked + label::after,
.icheck-material-deeppurple > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #673ab7; }

.icheck-material-indigo > input:first-child {
  background-color: #3f51b5; }
  .icheck-material-indigo > input:first-child::-ms-check {
    background-color: #3f51b5; }
  .icheck-material-indigo > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-indigo > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #3f51b5; }
  .icheck-material-indigo > input:first-child:checked + label::before,
  .icheck-material-indigo > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .icheck-material-indigo > input:first-child:checked + label::after,
  .icheck-material-indigo > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-indigo > input[type="radio"]:first-child:checked + label::after,
.icheck-material-indigo > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #3f51b5; }

.icheck-material-blue > input:first-child {
  background-color: #2196f3; }
  .icheck-material-blue > input:first-child::-ms-check {
    background-color: #2196f3; }
  .icheck-material-blue > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-blue > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #2196f3; }
  .icheck-material-blue > input:first-child:checked + label::before,
  .icheck-material-blue > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #2196f3;
    border-color: #2196f3; }
  .icheck-material-blue > input:first-child:checked + label::after,
  .icheck-material-blue > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-blue > input[type="radio"]:first-child:checked + label::after,
.icheck-material-blue > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #2196f3; }

.icheck-material-lightblue > input:first-child {
  background-color: #03a9f4; }
  .icheck-material-lightblue > input:first-child::-ms-check {
    background-color: #03a9f4; }
  .icheck-material-lightblue > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-lightblue > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #03a9f4; }
  .icheck-material-lightblue > input:first-child:checked + label::before,
  .icheck-material-lightblue > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #03a9f4;
    border-color: #03a9f4; }
  .icheck-material-lightblue > input:first-child:checked + label::after,
  .icheck-material-lightblue > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-lightblue > input[type="radio"]:first-child:checked + label::after,
.icheck-material-lightblue > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #03a9f4; }

.icheck-material-cyan > input:first-child {
  background-color: #00bcd4; }
  .icheck-material-cyan > input:first-child::-ms-check {
    background-color: #00bcd4; }
  .icheck-material-cyan > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-cyan > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #00bcd4; }
  .icheck-material-cyan > input:first-child:checked + label::before,
  .icheck-material-cyan > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .icheck-material-cyan > input:first-child:checked + label::after,
  .icheck-material-cyan > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-cyan > input[type="radio"]:first-child:checked + label::after,
.icheck-material-cyan > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #00bcd4; }

.icheck-material-teal > input:first-child {
  background-color: #009688; }
  .icheck-material-teal > input:first-child::-ms-check {
    background-color: #009688; }
  .icheck-material-teal > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-teal > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #009688; }
  .icheck-material-teal > input:first-child:checked + label::before,
  .icheck-material-teal > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #009688;
    border-color: #009688; }
  .icheck-material-teal > input:first-child:checked + label::after,
  .icheck-material-teal > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-teal > input[type="radio"]:first-child:checked + label::after,
.icheck-material-teal > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #009688; }

.icheck-material-green > input:first-child {
  background-color: #4caf50; }
  .icheck-material-green > input:first-child::-ms-check {
    background-color: #4caf50; }
  .icheck-material-green > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-green > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #4caf50; }
  .icheck-material-green > input:first-child:checked + label::before,
  .icheck-material-green > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #4caf50;
    border-color: #4caf50; }
  .icheck-material-green > input:first-child:checked + label::after,
  .icheck-material-green > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-green > input[type="radio"]:first-child:checked + label::after,
.icheck-material-green > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #4caf50; }

.icheck-material-lightgreen > input:first-child {
  background-color: #8bc34a; }
  .icheck-material-lightgreen > input:first-child::-ms-check {
    background-color: #8bc34a; }
  .icheck-material-lightgreen > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-lightgreen > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #8bc34a; }
  .icheck-material-lightgreen > input:first-child:checked + label::before,
  .icheck-material-lightgreen > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #8bc34a;
    border-color: #8bc34a; }
  .icheck-material-lightgreen > input:first-child:checked + label::after,
  .icheck-material-lightgreen > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-lightgreen > input[type="radio"]:first-child:checked + label::after,
.icheck-material-lightgreen > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #8bc34a; }

.icheck-material-lime > input:first-child {
  background-color: #cddc39; }
  .icheck-material-lime > input:first-child::-ms-check {
    background-color: #cddc39; }
  .icheck-material-lime > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-lime > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #cddc39; }
  .icheck-material-lime > input:first-child:checked + label::before,
  .icheck-material-lime > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #cddc39;
    border-color: #cddc39; }
  .icheck-material-lime > input:first-child:checked + label::after,
  .icheck-material-lime > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-lime > input[type="radio"]:first-child:checked + label::after,
.icheck-material-lime > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #cddc39; }

.icheck-material-yellow > input:first-child {
  background-color: #ffeb3b; }
  .icheck-material-yellow > input:first-child::-ms-check {
    background-color: #ffeb3b; }
  .icheck-material-yellow > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-yellow > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #ffeb3b; }
  .icheck-material-yellow > input:first-child:checked + label::before,
  .icheck-material-yellow > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #ffeb3b;
    border-color: #ffeb3b; }
  .icheck-material-yellow > input:first-child:checked + label::after,
  .icheck-material-yellow > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-yellow > input[type="radio"]:first-child:checked + label::after,
.icheck-material-yellow > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #ffeb3b; }

.icheck-material-amber > input:first-child {
  background-color: #ffc107; }
  .icheck-material-amber > input:first-child::-ms-check {
    background-color: #ffc107; }
  .icheck-material-amber > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-amber > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #ffc107; }
  .icheck-material-amber > input:first-child:checked + label::before,
  .icheck-material-amber > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #ffc107;
    border-color: #ffc107; }
  .icheck-material-amber > input:first-child:checked + label::after,
  .icheck-material-amber > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-amber > input[type="radio"]:first-child:checked + label::after,
.icheck-material-amber > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #ffc107; }

.icheck-material-orange > input:first-child {
  background-color: #ff9800; }
  .icheck-material-orange > input:first-child::-ms-check {
    background-color: #ff9800; }
  .icheck-material-orange > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-orange > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #ff9800; }
  .icheck-material-orange > input:first-child:checked + label::before,
  .icheck-material-orange > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #ff9800;
    border-color: #ff9800; }
  .icheck-material-orange > input:first-child:checked + label::after,
  .icheck-material-orange > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-orange > input[type="radio"]:first-child:checked + label::after,
.icheck-material-orange > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #ff9800; }

.icheck-material-deeporange > input:first-child {
  background-color: #ff5722; }
  .icheck-material-deeporange > input:first-child::-ms-check {
    background-color: #ff5722; }
  .icheck-material-deeporange > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-deeporange > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #ff5722; }
  .icheck-material-deeporange > input:first-child:checked + label::before,
  .icheck-material-deeporange > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #ff5722;
    border-color: #ff5722; }
  .icheck-material-deeporange > input:first-child:checked + label::after,
  .icheck-material-deeporange > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-deeporange > input[type="radio"]:first-child:checked + label::after,
.icheck-material-deeporange > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #ff5722; }

.icheck-material-brown > input:first-child {
  background-color: #795548; }
  .icheck-material-brown > input:first-child::-ms-check {
    background-color: #795548; }
  .icheck-material-brown > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-brown > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #795548; }
  .icheck-material-brown > input:first-child:checked + label::before,
  .icheck-material-brown > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #795548;
    border-color: #795548; }
  .icheck-material-brown > input:first-child:checked + label::after,
  .icheck-material-brown > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-brown > input[type="radio"]:first-child:checked + label::after,
.icheck-material-brown > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #795548; }

.icheck-material-grey > input:first-child {
  background-color: #9e9e9e; }
  .icheck-material-grey > input:first-child::-ms-check {
    background-color: #9e9e9e; }
  .icheck-material-grey > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-grey > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #9e9e9e; }
  .icheck-material-grey > input:first-child:checked + label::before,
  .icheck-material-grey > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #9e9e9e;
    border-color: #9e9e9e; }
  .icheck-material-grey > input:first-child:checked + label::after,
  .icheck-material-grey > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-grey > input[type="radio"]:first-child:checked + label::after,
.icheck-material-grey > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #9e9e9e; }

.icheck-material-bluegrey > input:first-child {
  background-color: #607d8b; }
  .icheck-material-bluegrey > input:first-child::-ms-check {
    background-color: #607d8b; }
  .icheck-material-bluegrey > input:first-child:not(:checked):not(:disabled):hover + label::before,
  .icheck-material-bluegrey > input:first-child:not(:checked):not(:disabled):hover + input[type="hidden"] + label::before {
    border-color: #607d8b; }
  .icheck-material-bluegrey > input:first-child:checked + label::before,
  .icheck-material-bluegrey > input:first-child:checked + input[type="hidden"] + label::before {
    background-color: #607d8b;
    border-color: #607d8b; }
  .icheck-material-bluegrey > input:first-child:checked + label::after,
  .icheck-material-bluegrey > input:first-child:checked + input[type="hidden"] + label::after {
    border-bottom-color: #fff;
    border-right-color: #fff; }

.icheck-material-bluegrey > input[type="radio"]:first-child:checked + label::after,
.icheck-material-bluegrey > input[type="radio"]:first-child:checked + input[type="hidden"] + label::after {
  background-color: #607d8b; }
